@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&display=swap');
@import '../../node_modules/bootstrap/scss/_functions.scss';
@import '../../node_modules/bootstrap/scss/_variables.scss';
@import '../../node_modules/bootstrap/scss/mixins/_breakpoints.scss';

$orange: #ea6420;
$white: #ffffff;

//Responsive
@media screen and (max-width: 768px) {
  div.HomeButton1.mb-4.float-end {
    float: none !important;
    text-align: center !important;
  }
}
@media screen and (max-width: 768px) {
  body {
    padding-top: 80px;
  }
}
@media screen and (max-width: 1280px) {
  .homesec1iamge {
    margin-top: 120px !important;
    max-width: 500px !important;
  }
}
@media screen and (max-width: 1085px) {
  .homesec1iamge {
    margin-top: 160px !important;
    max-width: 465px !important;
  }
}
@media screen and (max-width: 953px) {
  .homesec1iamge {
    max-width: 365px !important;
  }
}
@media screen and (max-width: 767px) {
  .homesec1iamge {
    max-width: 350px !important;
    margin-top: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .fadeInUpp-animation3 {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .latestnews {
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 768px) {
  .css-55vwbm-MuiTimeline-root {
    padding: 0 !important;
    display: contents !important;
  }
}
@media screen and (max-width: 768px) {
  div.container.py-2.zenseform {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .enabletechform {
    display: block !important;
  }
  .abouttext2 {
    text-align: center;
  }
}
@media all and (min-width: 992px) {
  .navbar .nav-item .dropdown-menu {
    display: none;
  }
  .navbar .nav-item:hover .dropdown-menu {
    display: block;
  }
  .navbar .nav-item .dropdown-menu {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .image_a {
    max-width: 210px !important;
  }
}
@media screen and (max-width: 576px) {
  div.row.mb-5 {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .IoTholder {
    padding-top: 0px !important;
  }
}
@media screen and (max-width: 768px) {
  .enabletechimg {
    width: inherit;
    margin-bottom: -15px;
  }
}
@media screen and (max-width: 820px) {
  p.zensetext.py-5 {
    padding-top: 0rem !important;
  }
}
@media screen and (max-width: 1200px) {
  .zensetextt {
    font-size: 9px;
  }
}
@media screen and (max-width: 768px) {
  .zensetextt {
    font-size: 7px;
  }
}
@media screen and (max-width: 576px) {
  div.container.py-2.zenseform {
    display: inline-block !important;
    text-align: center;
  }
}
@media screen and (max-width: 576px) {
  html,
  body {
    overflow-x: hidden !important;
  }
}
@keyframes fadeInUp {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes fadeInUpp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes fadeInUppp {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes fadeInUpppp {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@mixin background-image {
  background-repeat: no-repeat;
  background-size: 1903 x 531.84;
}
.enabletechform {
  display: flex;
}
div.container.py-2.zenseform {
  display: flex;
}
.fadeInUp-animation {
  animation: 1.5s fadeInUp;
  transition: 0.35s;
  font-size: 70px;
  font-family: 'Lato', sans-serif;
  color: #eceaea;
  margin-bottom: 10px;

  animation: shine 7s linear infinite;
  @keyframes shine {
    to {
      background-position: 200% center;
    }
  }
}
.fadeInUp-animation:hover {
  transform: scale(1.02);
}
.fadeInUp-animation1 {
  animation: 1.8s fadeInUp;
  transition: 0.65s;
}
.fadeInUp-animation1:hover {
  transform: scale(1.02);
}
.fadeInUp-animation2 {
  animation: 1.8s fadeInUp;
  transition: 0.65s;
}
.fadeInUp-animation2:hover {
  transform: scale(1.02);
}
.fadeInUpp-animation3 {
  //display: flex;
  animation: 3s fadeInUpp;
  transition: 0.65s;
  flex-direction: row;
  gap: 1rem;
  margin-top: 10px;
  button {
    width: 180px;
    cursor: pointer;
    //font-family: 'Lato', sans-serif;
    font-size: small;
    //border: 1px solid $orange;
    border-radius: 50px;
    //color: rgb(127, 127, 127);
    background-color: white;
    box-shadow: 0px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
  }
  button:hover,
  button:focus {
    transform: scale(1.02);
    color: white;
    background-color: $orange;
    box-shadow: 0px 0px 6px 4px $orange;
  }
}

//Body & Html

* {
  font-family: 'Mulish', sans-serif;
}
body {
  padding-top: 80px;
}
html,
body {
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  scroll-behavior: auto;
}
a {
  text-decoration: none !important;
}
.row1 {
  flex-wrap: nowrap !important;
}
//Navbar

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}
.navbar-nav {
  //--bs-nav-link-padding-y: 0 !important;
  color: rgb(246, 246, 246);
}
.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}
.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}
.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
.navbar-nav .nav-link.active {
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: rgb(234, 100, 32);
}
.nav-button-dropdown {
  padding: 16px;
  text-decoration: none;
  cursor: pointer;
  border-bottom: 2px solid rgb(246, 246, 246);
  //flex: 1;
  //text-align: center;
}
.nav-button-dropdown:hover {
  color: $orange;
  border-bottom-width: 2.2px;
  border-bottom-style: solid;
  border-bottom-color: rgba(234, 99, 32, 0.6);
}
.navlogo1 {
  width: 100px;
  padding-right: 1rem;
  animation: 1.5s fadeInUppp;
  transition: 0.3s;
}
.navlogo1:hover {
  transform: scale(1.01);
}
.centernav {
  margin-left: auto;
  margin-right: auto;
}
//Footer

.footer-top {
  margin-top: 10px;
}
.footerlogo {
  margin-top: -7px;
}
.footericon {
  margin: auto;
  text-align: center;
  //width: 75px;
}

//Background

.sec_1 {
  background-image: url('../images/Home\ Page/Home_bg.webp');
  background-position: 0% 100%;
}
.sec_5 {
  background-image: linear-gradient(
      0deg,
      rgb(235, 186, 82),
      rgba(243, 183, 92, 0.116)
    ),
    url('../images/Home\ Page/Home_BG3.webp');
  @include background-image;
  //height: 100%;
  //background-repeat: no-repeat;
  background-size: 560px;
  //background-position: left;
}
.col-home1 {
  background-color: rgba(243, 145, 26, 0.65);
  transition: 770ms;
}
.col-home1:hover {
  background-color: rgba(242, 145, 26, 0.7);
  transition: 380ms;
}
.col-home3 {
  background-color: #ffffff;
}
.col-home4 {
  background-image: url('../images/Home\ Page/Home_BG2.webp');
  //@include background-image;
  background-position: 600px -200px;
  background-attachment: fixed;
  //background-repeat: no-repeat;
  box-shadow: inset 0 0 0 100vmax rgba(255, 162, 41, 0.6);
  transition: 770ms;
}
.col-home4:hover {
  box-shadow: inset 0 0 0 100vmax rgba(255, 162, 41, 0.64);
  transition: 390ms;
}
.homesec1iamge {
  display: flex;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 556px;
  animation: 1.45s fadeInUpppp;
  transition: 0.45s;
}
.homesec1iamge:hover {
  transform: scale(1.01);
}
.Background1 {
  margin-top: 0px;
  margin-bottom: 0px;

  border-top-width: 100px;
  border-bottom-width: 50px;

  padding-top: 200px;
  padding-bottom: 50px;

  background-image: url('../images/About\ Page/AboutUs_BG.svg');
  @include background-image;
  //height: 100%;
  //background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.Background2 {
  margin-top: 0px;
  margin-bottom: 0px;

  border-top-width: 100px;
  border-bottom-width: 50px;

  padding-top: 200px;
  padding-bottom: 50px;

  background-image: url('../images/Zense/Zense_BG.webp');
  @include background-image;
  background-size: contain;
}
.Background3 {
  margin-top: 0px;
  margin-bottom: 0px;

  border-top-width: 100px;
  border-bottom-width: 50px;

  padding-top: 200px;
  padding-bottom: 50px;

  background-image: url('../images/Enabling\ Technologies/Enabling Technologies_BG.webp');
  @include background-image;
  //height: 100%;
  //background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.Background4 {
  margin-top: 0px;
  margin-bottom: 0px;

  border-top-width: 100px;
  border-bottom-width: 50px;

  padding-top: 200px;
  padding-bottom: 50px;

  background-image: url('../images/News/News_BG.svg');
  @include background-image;
  //height: 100%;
  //background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.Background5 {
  margin-top: 0px;
  margin-bottom: 0px;

  border-top-width: 100px;
  border-bottom-width: 50px;

  padding-top: 200px;
  padding-bottom: 50px;

  background-image: url('../images/Career\ Opportunity/Career\ Opportunity_BG.webp');
  @include background-image;
  //height: 100%;
  //background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.Background6 {
  margin-top: 0px;
  margin-bottom: 0px;

  border-top-width: 100px;
  border-bottom-width: 50px;

  padding-top: 200px;
  padding-bottom: 50px;

  background-image: url('../images/Contact\ Us/Contact\ Us_BG.webp');
  @include background-image;
  //height: 100%;
  //background-attachment: fixed;
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}
.BackgroundNews {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
}
.BackgroundNewss {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 100px;
  // margin-left: 50px;
  // margin-right: 50px;
}
.BackgroundForm {
  background-color: #ffffff;
  margin-left: 6%;
  margin-right: 6%;
  box-shadow: 0px 0px 12px 4px #eceaea;
}
.BackgroundForm > .zenseform:nth-child(odd) > img {
  order: 1;
}
.BackgroundForm > .zenseform:nth-child(odd) > div {
  order: 2;
}
.BackgroundForm > .zenseform:nth-child(even) > img {
  order: 2;
}
.BackgroundForm > .zenseform:nth-child(even) > div {
  order: 1;
}
.css-46bh2p-MuiCardContent-root:last-child {
  text-align: center;
}
.bg-grey {
  background: linear-gradient(rgb(250, 248, 248), rgba(236, 157, 117, 0.05));
}
#marker-example {
  max-width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

//News

.news-header {
  font-weight: 500;
}
.news-description {
  color: #646464;
}

//Text

li > a {
  color: #333;
  text-decoration: none;
  display: inline-block;
  position: relative;
}
li > a::after {
  content: '';
  display: block;
  margin: auto;
  height: 3px;
  width: 0;
  top: 5px;
  background: transparent;
  transition: all 0.3s;
}
.text-sec1 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 45px;
  color: #f7a71fec;
}
.text-sec2b {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 45px;
  color: #f7a71fec;
}
.text-sec5 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 45px;
  color: #f7a71fec;
}
.text-sec6 {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 45px;
  color: #f7a71fec;
}
.text-sec2 {
  font-size: 40px;
  animation: 1.45s fadeInUppp;
  transition: 0.35s;
}
.text-sec2:hover {
  transform: scale(1.03);
}
.text-sec2a {
  font-family: 'Lato', sans-serif;
  color: #808080;
}
.text-sec3 {
  font-size: large;
  font-weight: 500;
  animation: 1.5s fadeInUpp;
}
@media screen and (max-width: 916px) {
  div.text-sec3 br {
    display: none;
  }
}
.text-sec6a {
  color: $white;
  cursor: pointer;
}
.abouttext1 {
  color: #808080;
}
.abouttext2 {
  color: #f2911a;
}
.zensetext {
  font-weight: 600;
  animation: 1.55s fadeInUppp;
  transition: 0.45s;
}
.zensetextt {
  font-weight: 500;
  animation: 1.55s fadeInUppp;
  transition: 0.45s;
}
.careertext {
  color: #808080;
}

//Button

.btn {
  cursor: pointer !important;
  color: #fff !important;
}
.btn_shadow {
  border-radius: 50px;
  box-shadow: 0px 0px 4px 0px $orange;
}
.btn_shadow1 {
  border-radius: 100px;
  box-shadow: 0px 0px 1px 0px $orange;
}
.btn_custom {
  border-radius: 50px;
  background-color: white !important;
  color: #fdb12a !important;
  min-width: 80px;
  transition: all 0.3s ease 0s;
}
.btn_custom1 {
  background-color: #fdb12a !important;
}
.HomeButton {
  button {
    width: 190px;
    height: 45px;
    cursor: default;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
  }
  button:hover,
  button:focus {
    color: white;
    background-color: $orange;
    box-shadow: 0px 0px 12px 4px $orange;
  }
}
.HomeButton1 {
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 0px;

  button {
    width: 200px;
    height: 45px;
    cursor: pointer;
    border-radius: 50px;
    background-color: white;
    box-shadow: 0px;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out,
      box-shadow 0.2s ease-in-out;
  }
  button:hover,
  button:focus {
    color: white;
    background-color: $orange;
    //box-shadow: 0px 0px 12px 4px $orange;
  }
}
.HomeButton1 button:hover,
.HomeButton1 button:focus {
  background-color: #f2911a !important;
}
.css-1knaqv7-MuiButtonBase-root-MuiButton-root {
  text-transform: none !important;
  font-size: initial !important;
  color: #f2911a !important;
}
.css-kpm0tx-MuiButtonBase-root-MuiButton-root {
  color: #f2911a !important;
  padding: 0px !important;
}
.readmore {
  margin-left: 200px;
}

//Cards

.zensecards {
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.zensecards:hover {
  transform: scale(1.04);
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}
.css-1g3izzu {
  color: white !important;
}
.card {
  padding: 1.5em 0.5em 0.5em;
  border-radius: 2em;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
.card1 {
  width: 620px;
  height: 310px;
  padding: 1.5em 0.5em 0.5em;
  border-radius: 2em;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
}
CardContent {
  padding: 50px;
}
.CardHeader {
  background-color: #fdb12a;
}
.css-ny6982-MuiPaper-root-MuiCard-root {
  background-color: rgba(255, 255, 255, 0.96) !important;
  text-align: center;
}
.css-bhp9pd-MuiPaper-root-MuiCard-root {
  box-shadow: 0px 1px 1px -1px #2c0e0e, 2px 2px 2px 2px #eee8e8,
    0px 1px 1px 0px white !important;
  margin: auto;
}
.card2022 {
  animation: 1.45s fadeInUpppp;
  transition: 0.45s;
}
.card2021 {
  animation: 1.55s fadeInUp;
  transition: 0.45s;
}
.card2020 {
  animation: 1.65s fadeInUpppp;
  transition: 0.45s;
}
.card2019 {
  animation: 1.75s fadeInUp;
  transition: 0.45s;
}
.card2018 {
  animation: 1.85s fadeInUpppp;
  transition: 0.45s;
}
.css-s18byi {
  box-shadow: 0px 1px 1px -1px #2c0e0e, 2px 2px 2px 2px #eee8e8,
    0px 1px 1px 0px white !important;
  margin: auto;
}
.css-1okfn8i {
  box-shadow: 0px 1px 1px -1px #2c0e0e, 0px 2px 1px 1px #eee8e8,
    0px 1px 1px 0px white !important;
}
// Not Labelled

.draw-line {
  content: '';
  display: block;
  background-color: rgba(199, 197, 197, 0.5);
  height: 3px;
  margin-top: 30px;
  display: flex;
  align-items: center;
}
.awardimage:hover {
  opacity: 90%;
}
.awardimage {
  text-align: center;
}
.image-fluid {
  transition: 0.55s;
}
.image-fluid:hover {
  transform: scale(1.03);
}
.css-sj49mn {
  color: #f2911a !important;
  padding: 0px !important;
}
.css-1qvr50w-MuiTypography-root {
  color: white;
}
.fIWhqc {
  margin: 0px !important;
}
.box_border {
  border: solid 1px #ececec !important;
}
.secondary_header {
  height: 300px;
  width: 100%;
}
.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.diMzUA {
  margin: 0px !important;
}
.zenseform {
  gap: 30px;
}
.icongap {
  display: inline-flex;
  margin-left: 10px;
}
.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}
.grid img {
  width: 80%;
}
.image_ccl {
  max-width: 215px !important;
  cursor: pointer;
}
.image_ccl:hover {
  opacity: 0.8;
}
.image_airport {
  max-width: 165px !important;
  cursor: pointer;
}
.image_airport:hover {
  opacity: 0.8;
}
.image_cedd {
  max-width: 215px !important;
  cursor: pointer;
}
.image_cedd:hover {
  opacity: 0.8;
}
.image_cloud {
  max-width: 235px !important;
  cursor: pointer;
}
.image_cloud:hover {
  opacity: 0.8;
}

.image_aws {
  max-width: 110px !important;
  cursor: pointer;
}
.image_aws:hover {
  opacity: 0.8;
}
.App {
  font-family: sans-serif;
  text-align: center;
}
.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.timeline {
  height: 300px;
  width: 5px;
  background-color: red;
}
.stub1 {
  line-height: 300px;
  font-size: 24px;
  background-color: #db0606;
}
.stub2 {
  height: 1000px;
}
.circle {
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgb(245, 14, 14);
  border-radius: 50%;
  background-color: #c90e0e;
}
.circleWrapper {
  position: relative;
}
.message {
  position: absolute;
  top: 20%;
  left: 50%;
  min-width: 150px;
  font-weight: bold;
}
.TFJKt {
  overflow: hidden !important;
}
.icwDDZ::before {
  background: #adadac !important;
}
.icwDDZ::after {
  background: #adadac !important;
}
.cSmnsA:not(.using-icon) {
  background: #fcb02a !important;
}
.fqlgVE {
  color: #f2911a !important;
}
.fqlgVE.active {
  background: #ffffff !important;
}
.cSmnsA:not(.using-icon).active {
  border: 3px solid #f4f4f4 !important;
}
.css-v6mo1y-MuiTimelineDot-root {
  width: 25px;
  height: 25px;
}
.css-1nqcc1s {
  width: 25px;
  height: 25px;
  background-color: #f2911a !important;
}
.css-n2itgq-MuiTypography-root-MuiTimelineOppositeContent-root {
  padding: 13px 16px !important;
  color: #fcb02a !important;
  animation: 1.55s fadeInUppp;
  transition: 0.45s;
}
.css-16ut1rm {
  padding: 13px 16px !important;
  color: #fcb02a !important;
  animation: 1.55s fadeInUppp;
  transition: 0.45s;
}
.css-zq6grw {
  color: #fcb02a;
}
.css-v6mo1y-MuiTimelineDot-root {
  background-color: #fcb02a !important;
}
.css-ag7rrr-MuiTypography-root {
  color: #fcb02a;
}
button.control-arrow.control-next {
  background-color: #e4d7ff !important;
}
button.control-arrow.control-prev {
  background-color: #e4d7ff !important;
}
p.legend {
  background: rgba(0, 0, 0, 0.7) !important;
}
.newstext {
  color: #6bb0fa;
}
.css-1sohoek-MuiTimelineItem-root:nth-of-type(even) .MuiTimelineContent-root {
  text-align: start !important;
}
.css-lvko3p:nth-of-type(2n) .MuiTimelineContent-root {
  text-align: start !important;
}
.css-12dv9jb {
  min-width: 160px !important;
}
a.nav-button-dropdown.dropdown-item.active {
  border-bottom: 2px solid #ea6420;
  color: #16181b;
  background-color: #eee;
}
.css-1v5z4dq-MuiPaper-root-MuiCard-root {
  box-shadow: 0px 1px 1px -1px #2c0e0e, 0px 2px 1px 1px #eee8e8,
    0px 1px 1px 0px white !important;
}
.css-1qw96cp:last-child {
  text-align: center;
}
ul#navbarNavAltMarkup.dropdown-menu.list-unstyled.collapse.show {
  display: none;
}
span.nav-item.nav-link.px-lg-3.px-sm-3.px-1:hover
  > ul#navbarNavAltMarkup.dropdown-menu.list-unstyled.collapse.show {
  display: block;
}
span.nav-item.nav-link.toggle-collapse.px-lg-3.px-sm-3.px-1 {
  cursor: default;
}
.css-68o8xu {
  text-align: left;
}
.imageA {
  max-width: 235px !important;
}
.imageB {
  max-width: 200px !important;
}
.imageC {
  max-width: 140px !important;
}
.partner-div {
  align-items: center !important;
  justify-content: space-evenly !important;
}
@media screen and (max-width: 434px) {
  .imageA {
    max-width: none !important;
  }
  .imageB {
    max-width: none !important;
  }
  .imageC {
    max-width: none !important;
  }
  .partner-div {
    text-align: center !important;
  }
}
